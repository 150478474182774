// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./assets/fonts/Haettenschweiler.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./assets/fonts/Haettenschweiler Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "./assets/fonts/Eras Bold ITC.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Haettenschweiler\";\n  src: local(\"Haettenschweiler\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  /* Super Modern Browsers */\n}\n@font-face {\n  font-family: \"HaettenschweilerRegular\";\n  src: local(\"HaettenschweilerRegular\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n  /* Super Modern Browsers */\n}\n@font-face {\n  font-family: \"ErasBoldItc\";\n  src: local(\"ErasBoldItc\"), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n  /* Super Modern Browsers */\n}\n.App {\n  position: relative;\n  width: 100vw;\n  height: 100vh;\n}\n\n.fullScreen {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://src/App.scss"],"names":[],"mappings":"AAAA;EACE,+BAAA;EACA,0FAAA;EACiE,0BAAA;AACnE;AAEA;EACE,sCAAA;EACA,iGAAA;EACyE,0BAAA;AAA3E;AAGA;EACE,0BAAA;EACA,qFAAA;EAC8D,0BAAA;AADhE;AAIA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;AAFF;;AAKA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAFF","sourcesContent":["@font-face {\n  font-family: \"Haettenschweiler\";\n  src: local(\"Haettenschweiler\"),\n    url(\"./assets/fonts/Haettenschweiler.ttf\") format(\"truetype\"); /* Super Modern Browsers */\n}\n\n@font-face {\n  font-family: \"HaettenschweilerRegular\";\n  src: local(\"HaettenschweilerRegular\"),\n    url(\"./assets/fonts/Haettenschweiler Regular.ttf\") format(\"truetype\"); /* Super Modern Browsers */\n}\n\n@font-face {\n  font-family: \"ErasBoldItc\";\n  src: local(\"ErasBoldItc\"),\n    url(\"./assets/fonts/Eras Bold ITC.ttf\") format(\"truetype\"); /* Super Modern Browsers */\n}\n\n.App {\n  position: relative;\n  width: 100vw;\n  height: 100vh;\n}\n\n.fullScreen {\n  position: relative;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
