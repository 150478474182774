// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/modal_back.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GameScene {\n  position: relative;\n  display: grid;\n  align-items: center;\n  text-align: center;\n  width: 100vw;\n  height: 100vh;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n}\n.GameScene .game-container {\n  position: relative;\n  margin: auto;\n}\n.GameScene .game-container .game-canvas {\n  width: 100%;\n  height: 100%;\n}", "",{"version":3,"sources":["webpack://src/views/GameScene/GameScene.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;EACA,yDAAA;EACA,4BAAA;EACA,0BAAA;AACF;AAAE;EACE,kBAAA;EACA,YAAA;AAEJ;AADI;EACE,WAAA;EACA,YAAA;AAGN","sourcesContent":[".GameScene {\n  position: relative;\n  display: grid;\n  align-items: center;\n  text-align: center;\n  width: 100vw;\n  height: 100vh;\n  background-image: url(\"../../assets/img/modal_back.png\");\n  background-repeat: no-repeat;\n  background-size: 100% 100%;\n  .game-container {\n    position: relative;\n    margin: auto;\n    .game-canvas {\n      width: 100%;\n      height: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
